import {Container, Row, Col} from 'reactstrap'
import {NavMenu} from './NavMenu'
import {useMediaQuery} from 'react-responsive'

export const Layout = (props: any) =>
{
  const year = new Date().getFullYear()

  // Media query to detect if the device is a mobile device (less than 768px width)
  const isMobile = useMediaQuery({query: '(max-width: 768px)'})

  // Define NavMenu height based on device type (adjust these values to match your actual NavMenu height)
  const navMenuHeight = isMobile ? 75 : 75

  return (
    <div
      className="d-flex flex-column min-vh-100"
      style={{backgroundColor: '#f5f5f5'}}
    >
      {/* Apply the background color here */}
      <NavMenu />
      {/* Scrollable content area */}
      <Container
        className="flex-grow-1"
        style={{
          marginTop: `${navMenuHeight + 20}px`, // Adjusted marginTop
          paddingBottom: '70px', // Existing paddingBottom
        }}
      >
        <Row>
          <Col sm="12" md={{size: 10, offset: 1}}>
            {props.children}
          </Col>
        </Row>
      </Container>
      {/* Fixed footer at the bottom */}
      <footer
        className="fixed-bottom text-center p-2 bg-light border-top"
        style={{
          fontSize: isMobile ? '12px' : '16px',
          backgroundColor: '#f5f5f5',
        }}
      >
        <p className="mb-0">
          &copy; {year} Direct Democracy Corporation. All rights reserved.
        </p>
      </footer>
    </div>
  )
}
// src/components/referendum/ReferendumPage.tsx

import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {AppPaths} from '../../AppPaths'
import {FaVoteYea, FaInfoCircle} from 'react-icons/fa'

import infra from '../../images/infra.jpg'
import education from '../../images/education.jpg'
import healthcare from '../../images/healthcare.jpg'
import housing from '../../images/housing.jpg'
import environment from '../../images/environment.jpg'
import foreignpolicy from '../../images/foreignpolicy.jpg'

import {useMediaQuery} from 'react-responsive'

import {useEffect} from 'react'
import {useLocation} from 'react-router-dom'


interface Referendum
{
  title: string
  description: string
  imageSrc: string
  path: string
  learnMorePath: string
}

export const ReferendumPage: React.FC = () =>
{
  const [expandedDescriptions, setExpandedDescriptions] = useState<{
    [key: number]: boolean
  }>({})
  const isMobile = useMediaQuery({query: '(max-width: 768px)'})

  const location = useLocation()


  useEffect(() =>
  {
    window.scrollTo(0, 0) // Resets the scroll position to the top
  }, [location])


  const referendums: Referendum[] = [
    {
      title: 'Education Referendum',
      description:
        'Support or reject a proposal to increase funding for public education to improve teacher salaries, school facilities, and student resources through redistributing budget allocations.',
      imageSrc: education,
      path: AppPaths.EducationReferendum,
      learnMorePath: AppPaths.EducationInsights,
    },
    {
      title: 'Healthcare Referendum',
      description:
        'Cast your vote on a proposal designed to overhaul prescription drug policy, addressing price negotiations, drug importation, cost caps, pricing transparency, and annual price increase control.',
      imageSrc: healthcare,
      path: AppPaths.HealthcareReferendum,
      learnMorePath: AppPaths.HealthcareInsights,
    },
    {
      title: 'Infrastructure Referendum',
      description:
        'Vote on a proposed tax reform that aims to fund public infrastructure improvements and social welfare programs through a new tax framework.',
      imageSrc: infra,
      path: AppPaths.TaxReferendum,
      learnMorePath: AppPaths.TaxInsights,
    },

    {
      title: 'Environment Referendum',
      description:
        'Vote on the proposed Private Property Environmental Protection Act, aiming to safeguard individual properties from pollution and promote a healthier environment.',
      imageSrc: environment,
      path: AppPaths.EnvironmentReferendum,
      learnMorePath: AppPaths.EnvironmentInsights,
    },
    {
      title: 'Housing Referendum',
      description:
        'Vote to support or reject the Shelter or Accountability proposal aimed at addressing homelessness through offering relocation to designated communities.',
      imageSrc: housing,
      path: AppPaths.HousingReferendum,
      learnMorePath: AppPaths.HousingInsights,
    },
    {
      title: 'Foreign Policy Referendum',
      description:
        'Vote on a proposed military operation in a neighboring country, a move aimed at strengthening our national security but potentially challenging international law and relationships.',
      imageSrc: foreignpolicy,
      path: AppPaths.ForeignPolicyReferendum,
      learnMorePath: AppPaths.ForeignPolicyInsights,
    },
  ]

  const toggleDescription = (index: number) =>
  {
    setExpandedDescriptions((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }))
  }

  // Styles
  const styles: {[key: string]: React.CSSProperties} = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '25px 20px',
      fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
      color: '#000',
    },
    header: {
      textAlign: 'center',
      marginBottom: '40px',
    },
    title: {
      fontSize: '32px',
      fontWeight: 500,
      marginBottom: '10px',
    },
    subtitle: {
      fontSize: '18px',
      fontWeight: 300,
      color: '#555',
    },
    referendumList: {
      display: 'flex',
      flexDirection: 'column',
      gap: '60px',
    },
    referendumItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
    image: {
      width: isMobile ? '90%' : '50%',
      maxWidth: '600px',
      height: 'auto',
      borderRadius: '12px',
      marginBottom: '20px',
    },
    referendumTitle: {
      fontSize: '24px',
      fontWeight: 500,
      marginBottom: '10px',
    },
    description: {
      fontSize: '16px',
      fontWeight: 300,
      lineHeight: 1.6,
      maxWidth: '600px',
      marginBottom: '20px',
      cursor: 'pointer',
    },
    buttonRow: {
      display: 'flex',
      gap: '20px',
      marginBottom: '40px',
    },
    button: {
      padding: '12px 24px',
      fontSize: isMobile ? '14px' : '16px',
      borderRadius: '30px',
      backgroundColor: '#000',
      color: '#fff',
      border: 'none',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      fontWeight: 500,
    },
    linkButton: {
      textDecoration: 'none',
    },
    voteHistorySection: {
      textAlign: 'center',
      marginTop: '80px',
    },
    voteHistoryButton: {
      padding: '12px 24px',
      fontSize: '16px',
      borderRadius: '30px',
      backgroundColor: '#000',
      color: '#fff',
      border: 'none',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      fontWeight: 500,
    },
    voteHistoryLink: {
      textDecoration: 'none',
      display: 'inline-block', // Ensures centering works properly

    },
  }

  return (
    <div style={styles.container}>
      {/* Header */}
      <header style={styles.header}>
        <h1 style={styles.title}>Learn by Example</h1>
        <p style={styles.subtitle}>
          Understand Direct Democracy Through Referendums
        </p>
      </header>

      {/* Referendum List */}
      <div style={styles.referendumList}>
        {referendums.map((referendum, index) =>
        {
          const isExpanded = expandedDescriptions[index]
          const displayText = isExpanded
            ? referendum.description
            : referendum.description.length > 150
              ? referendum.description.substring(0, 150) + '...'
              : referendum.description

          return (
            <div key={index} style={styles.referendumItem}>
              {/* Image */}
              <img
                src={referendum.imageSrc}
                alt={referendum.title}
                style={styles.image}
              />
              <h2 style={styles.referendumTitle}>{referendum.title}</h2>
              <p
                style={styles.description}
                onClick={() => toggleDescription(index)}
                role="button"
                tabIndex={0}
                onKeyPress={(e) =>
                {
                  if (e.key === 'Enter') toggleDescription(index)
                }}
              >
                {displayText}
              </p>
              <div style={styles.buttonRow}>
                <Link to={referendum.path} style={styles.linkButton}>
                  <button
                    style={styles.button}
                    aria-label={`Vote on ${referendum.title}`}
                  >
                    <FaVoteYea />
                    Vote Now
                  </button>
                </Link>
                <Link to={referendum.learnMorePath} style={styles.linkButton}>
                  <button
                    style={styles.button}
                    aria-label={`Learn more about ${referendum.title}`}
                  >
                    <FaInfoCircle />
                    Learn More
                  </button>
                </Link>
              </div>
            </div>
          )
        })}
      </div>

      {/* Vote History Section */}
      <div style={styles.voteHistorySection}>
        <h2 style={styles.title}>Vote History</h2>
        <p style={{fontSize: '16px', fontWeight: 300, marginBottom: '20px'}}>
          View your previously made votes on all referendums that you have
          participated in.
        </p>
        <Link to="/vote-history" style={styles.voteHistoryLink}>
          <button style={styles.voteHistoryButton}>
            <FaVoteYea />
            View Vote History
          </button>
        </Link>
      </div>
    </div>
  )
}

export default ReferendumPage